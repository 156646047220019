<template>
  <div class="contact-us-wrap">
    <public-banner :banner-info="bannerInfo" />
    <div class="contact-us container">
      <el-form ref="contactForm" :model="contactForm" :rules="contactRules" class="demo-ruleForm">
        <div class="basic">
          <div class="title">{{ LP.lang_company_infomation }}</div>
          <div class="title-line"></div>
          <el-form-item :label="LP.lang_company_name" prop="company_name">
            <el-input v-model="contactForm.company_name" />
          </el-form-item>
          <el-form-item :label="LP.lang_company_type" prop="company_type" class="select">
            <el-select v-model="contactForm.company_type" :placeholder="LP.lang_please_select" :no-data-text="LP.lang_no_data">
              <el-option v-for="(item, index) in companyTypeList" :key="index" :label="item.name" :value="item.name" />
            </el-select>
          </el-form-item>
          <el-form-item :label="LP.lang_job_title" prop="job_title">
            <el-input v-model="contactForm.job_title" />
          </el-form-item>
          <el-form-item :label="LP.lang_country_region" prop="country" class="select">
            <el-select v-model="contactForm.country" :placeholder="LP.lang_please_select" :no-data-text="LP.lang_no_data" @change="changeCountry">
              <el-option v-for="(item, index) in regionList" :key="index" :label="item.name" :value="item.name" />
            </el-select>
          </el-form-item>
          <el-form-item :label="LP.lang_province_state" prop="province" class="select">
            <el-select v-model="contactForm.province" :placeholder="LP.lang_please_select" :no-data-text="LP.lang_no_data" @change="changeCity">
              <el-option v-for="(item, index) in provinceList" :key="index" :label="item.name" :value="item.name" />
            </el-select>
          </el-form-item>
          <el-form-item :label="LP.lang_city_town" prop="city" class="select">
            <el-select v-model="contactForm.city" :placeholder="LP.lang_please_select" :no-data-text="LP.lang_no_data">
              <el-option v-for="(item, index) in cityList" :key="index" :label="item.name" :value="item.name" />
            </el-select>
          </el-form-item>
          <el-form-item :label="LP.lang_postcode" prop="postcode">
            <el-input v-model="contactForm.postcode" />
          </el-form-item>
          <el-form-item :label="LP.lang_message_comments" prop="message_comments" class="text-area">
            <el-input v-model="contactForm.message_comments" type="textarea" />
          </el-form-item>
          <el-form-item :label="LP.lang_business_verticals" prop="vertical" class="check-box">
            <el-checkbox-group v-model="contactForm.vertical">
              <el-checkbox v-for="(item, index) in checkList" :key="index" :label="item.name" :name="item.name" />
              <el-input v-model="checkOther" class="other-input" />
            </el-checkbox-group>
          </el-form-item>
        </div>
        <div class="basic">
          <div class="title">{{ LP.lang_user_information }}</div>
          <div class="title-line"></div>
          <el-form-item :label="LP.lang_contact_name" prop="name" class="radio">
            <el-radio-group v-model="contactForm.gender">
              <el-radio :label="LP.lang_mr">{{ LP.lang_mr }}</el-radio>
              <el-radio :label="LP.lang_ms">{{ LP.lang_ms }}</el-radio>
            </el-radio-group>
            <el-input v-model="contactForm.name" />
          </el-form-item>
          <el-form-item :label="LP.lang_email" prop="email">
            <el-input v-model="contactForm.email" />
          </el-form-item>
          <el-form-item :label="LP.lang_phone" prop="phone">
            <el-input v-model="contactForm.phone" />
          </el-form-item>
          <el-form-item class="agreen">
            <el-checkbox v-model="checked">
              {{ LP.lang_contact_privacy_policy_front }}
              <a href="https://www.dahuasecurity.com/aboutUs/privacy-policy" target="_blank" class="text">{{ LP.lang_privacy_policy }}</a>
              {{ LP.lang_contact_privacy_policy_behind }}
            </el-checkbox>
          </el-form-item>
          <el-form-item class="btn">
            <el-button type="primary" @click="submitForm">{{ LP.lang_submit }}</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import { mapState } from 'vuex'
import { getRegion, getCountry, getprovice, getContactUs } from '@/api/contactUs'
export default {
  components: {
    PublicBanner
  },
  data() {
    return {
      bannerInfo: {},
      contactForm: {
        company_name: '',
        company_type: '',
        job_title: '',
        country: '',
        province: '',
        city: '',
        postcode: '',
        message_comments: '',
        vertical: [],
        name: '',
        gender: '',
        email: '',
        phone: ''
      },
      contactRules: {
        company_name: [
          { required: true, message: this.LP.lang_company_name_tips, trigger: 'blur' }
        ],
        company_type: [
          { required: true, message: this.LP.lang_company_type_tips, trigger: 'blur' }
        ],
        country: [
          { required: true, message: this.LP.lang_region_tips, trigger: 'blur' }
        ],
        province: [
          { required: true, message: this.LP.lang_province_tips, trigger: 'blur' }
        ],
        city: [
          { required: true, message: this.LP.lang_city_tips, trigger: 'blur' }
        ],
        message_comments: [
          { required: true, message: this.LP.lang_message_comments_tips, trigger: 'blur' }
        ],
        vertical: [
          { required: true, message: this.LP.lang_targets, trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.LP.lang_name_tips, trigger: 'blur' }
        ],
        email: [
          { required: true, message: this.LP.lang_email_tips, trigger: 'blur' }
        ]
      },
      checkList: [ // 多选列表
        {
          name: this.LP.lang_safe_city
        },
        {
          name: this.LP.lang_traffic_transportation
        },
        {
          name: this.LP.lang_education
        },
        {
          name: this.LP.lang_oil
        },
        {
          name: this.LP.lang_finance
        },
        {
          name: this.LP.lang_estates
        },
        {
          name: this.LP.lang_hospitality_leisure
        },
        {
          name: this.LP.lang_utilities
        },
        {
          name: this.LP.lang_police
        },
        {
          name: this.LP.lang_rail
        },
        {
          name: this.LP.lang_commercial
        },
        {
          name: this.LP.lang_manufacturing
        },
        {
          name: this.LP.lang_ports
        },
        {
          name: this.LP.lang_retail
        },
        {
          name: this.LP.lang_healthcare
        },
        {
          name: this.LP.lang_government
        },
        {
          name: this.LP.lang_other
        }
      ],
      checkOther: '',
      companyTypeList: [// 公司类型
        {
          name: this.LP.lang_distributor
        },
        {
          name: this.LP.lang_reseller
        },
        {
          name: this.LP.lang_system_integrator
        },
        {
          name: this.LP.lang_installer
        },
        {
          name: this.LP.lang_end_user
        },
        {
          name: this.LP.lang_third_party
        },
        {
          name: this.LP.lang_organization
        },
        {
          name: this.LP.lang_manufacturer
        },
        {
          name: this.LP.lang_consultant
        }
      ],
      regionList: [], // 区
      provinceList: [], // 省份
      cityList: [], // 城市
      checked: false,
      seo: {
        meta_description: '',
        meta_keyword: '',
        meta_title: ''
      }
    }
  },
  computed: {
    ...mapState({
      banner: state => state.common.banner,
      whiteHeader: (state) => state.header.whiteHeader
    })
  },
  async created() {
    this.$store.commit('header/SET_WHITE_HEADER', true)
    if (this.$route.fullPath.includes('business')) {
      await this.$store.dispatch('common/getBanner', { banner_id: 222 })
    } else {
      await this.$store.dispatch('common/getBanner', { banner_id: 223 })
    }
    if (this.banner) {
      this.bannerInfo = this.banner[0]
      this.$store.commit('common/SET_MATE', this.bannerInfo)
    }
    this.getRegion()
  },
  methods: {
    // 获取国家
    getRegion() {
      getRegion({
        region: -1
      }).then(res => {
        this.regionList = res.data
      })
    },
    // 获取省份
    changeCountry(value) {
      this.contactForm.province = ''
      this.contactForm.city = ''
      this.cityList = []
      getCountry({
        country: value
      }).then(res => {
        this.provinceList = res.data
      })
    },
    // 获取城市
    changeCity(value) {
      this.contactForm.city = ''
      getprovice({
        province: value
      }).then(res => {
        this.cityList = res.data
      })
    },
    // 提交表单
    submitForm() {
      this.$refs.contactForm.validate(async (valid) => {
        if (valid) {
          if (this.checked) {
            if (this.contactForm.vertical.includes('Other:')) {
              const index = this.contactForm.vertical.findIndex(item => item === 'Other:')
              this.contactForm.vertical[index] = this.contactForm.vertical[index] + this.checkOther
            }
            await getContactUs(this.contactForm).then(res => {
              if (res.status === '00') {
                this.$message.success(this.LP.lang_submit_success)
                this.checked = false
                this.contactForm.gender = ''
                this.$refs.contactForm.resetFields()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error(this.LP.lang_agree_tips)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.contact-us-wrap {
  .contact-us {
    padding-top: 48px;
    .basic {
      margin-bottom: 28px;
      .title {
        font-size: 24px;
        font-weight: 600;
        color: #303133;
        line-height: 32px;
      }
      .title-line {
        margin-bottom: 20px;
        width: 44px;
        height: 4px;
        background: #D8D8D8;
      }
    }
  }
}
/deep/ .el-form {
  .el-form-item {
    display: inline-block;
    width: 32.5%;
    margin-bottom: 20px;
    margin-right: 16px;
    .el-form-item__label {
      text-align: left;
      width: 100%;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 21px;
    }
    .el-form-item__content {
      .el-input {
        width: 100%;
        height: 48px;
        .el-input__inner {
          width: 100%;
          height: 100%;
          font-size: 16px;
          color: #333;
          line-height: 24px;
          border: 1px solid #E4E7ED;
        }
      }
    }
    &:nth-of-type(3n-1) {
      margin-right: 0;
    }
  }
  .text-area {
    display: block;
    width: 100%;
    .el-textarea__inner {
       height: 120px;
       font-size: 18px;
       color: #303133;
       line-height: 24px;
       border: 1px solid #E4E7ED;
       resize: none;
    }
  }
  .select {
    .el-form-item__label {
      float: none;
      margin-bottom: 0px;
    }
    .el-form-item__content {
      margin-top: 10px;
      .el-select {
        width: 100%;
        .el-input__suffix {
          right: 8px;
        }
        .el-input {
          .el-input__inner {
            font-size: 16px;
            color: #333;
          }
          .el-select__caret {
            font-size: 24px;
          }
        }
      }
    }
  }
  .check-box {
    display: block;
    width: 100%;
    .el-form-item__label {
      float: none;
    }
    .el-checkbox {
      width: 20%;
      padding-right: 20px;
      margin-right: 0;
      &:last-of-type {
        width: 64px;
        padding-right: 0;
      }
    }
    .el-checkbox__label {
      white-space: pre-wrap;
      vertical-align: text-top;
    }
    .el-form-item__content {
      .other-input {
        width: 14%;
        height: 40px;
        .el-input__inner {
          color: #606266;
          font-size: 14px;
          border: none;
          border-bottom: 1px solid #E4E7ED;
        }
      }
    }
  }
  .radio {
    .el-radio-group {
      width: 25%;
      .el-radio {
        margin-right: 5px;
      }
    }
    .el-form-item__content {
      .el-input {
        width: 75%;
      }
    }
  }
  .agreen {
    width: 100%;
    margin-right: 0;
    .el-checkbox__label {
      white-space: pre-wrap;
      .text {
        color: @theme;
        text-decoration: underline;
      }
    }
    .el-checkbox__input {
      vertical-align: text-top;
    }
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: @theme;
  }
  .btn {
    display: block;
    width: 100%;
    text-align: center;
    .el-button {
      padding: 10px 20px;
      font-size: 16px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 21px;
    }
  }
}
/deep/ .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  content: '';
  margin-right: 0;
}
/deep/ .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:after {
  content: "*";
  color: #EC400B,
}
@media screen and (max-width: 1320px) {
  /deep/ .el-form {
    .el-form-item {
      width: 31.5%;
    }
    .text-area {
      width: 97.5%;
    }
    .check-box {
      width: 100%;
    }
    .agreen {
      width: 97.5%;
    }
    .radio {
      .el-radio-group {
        width: 40%;
      }
      .el-form-item__content {
        .el-input {
          width: 60%;
        }
      }
    }
    .btn {
      width: 100%;
    }
  }
}
@media screen and (max-width: 1024px) {
  /deep/ .el-form {
    .el-form-item {
      width: 48%;
      &:nth-of-type(3n-1) {
        margin-right: 16px;
      }
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
    .text-area {
      width: 100%;
    }
    .check-box {
      width: 100%;
      .el-checkbox {
        width: 25%;
      }
    }
    .agreen {
      width: 100%;
    }
    .btn {
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  /deep/ .el-form {
    .el-form-item {
      width: 100%;
      &:nth-of-type(3n-1) {
        margin-right: 0;
      }
    }
    .check-box {
      .el-checkbox {
        width: 50%;
      }
      .el-form-item__content {
        .other-input {
          width: 35%;
        }
      }
    }
    .radio {
      .el-radio-group {
        width: 20%;
      }
      .el-form-item__content {
        .el-input {
          width: 80%;
        }
      }
    }
  }
}
</style>
